/*
 * Copyright (C) 2018 - present Instructure, Inc.
 *
 * This file is part of Canvas.
 *
 * Canvas is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, version 3 of the License.
 *
 * Canvas is distributed in the hope that it will be useful, but WITHOUT ANY
 * WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 * A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License along
 * with this program. If not, see <http://www.gnu.org/licenses/>.
 */

import { CHANGE_TAB, CHANGE_ACCORDION, RESET_UI, HIDE_SIDEBAR, SHOW_SIDEBAR } from '../actions/ui';
import { combineReducers } from 'redux';
function hidden() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case HIDE_SIDEBAR:
      return true;
    case RESET_UI:
    case SHOW_SIDEBAR:
      return false;
    default:
      return state;
  }
}
function selectedTabIndex() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case RESET_UI:
      return 0;
    case CHANGE_TAB:
      return action.index;
    default:
      return state;
  }
}
function selectedAccordionIndex() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case RESET_UI:
      return '';
    case CHANGE_TAB:
      // switch links panel accordion tab back to first tab any time we
      // switch _back to_ links panel
      return '';
    case CHANGE_ACCORDION:
      try {
        if (action.index !== window.sessionStorage.getItem('canvas_rce_links_accordion_index')) {
          window.sessionStorage.setItem('canvas_rce_links_accordion_index', action.index);
        }
      } catch (err) {
        // If there is an error accessing session storage, just ignore it.
        // We are likely in a test environment
      }
      return action.index;
    default:
      return state;
  }
}
export default combineReducers({
  hidden,
  selectedTabIndex,
  selectedAccordionIndex
});