/*
 * Copyright (C) 2023 - present Instructure, Inc.
 *
 * This file is part of Canvas.
 *
 * Canvas is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, version 3 of the License.
 *
 * Canvas is distributed in the hope that it will be useful, but WITHOUT ANY
 * WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 * A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License along
 * with this program. If not, see <http://www.gnu.org/licenses/>.
 */

import RCEWrapper from '../../RCEWrapper';
import { fallbackIframeAllowances } from './constants';

/**
 * Type of the "editor buttons" that come from Canvas.
 *
 * They're actually the available LTI Tool configurations, so we give them a more reasonable name here.
 */

/**
 * Subset of TinyMCE used by the ExternalTools dialog. Used to document the subset of the API that we use so
 * it's easier to test.
 */

/**
 * Gets the environment information for the external tools dialog for a given tinyMCE editor.
 */
export function externalToolsEnvFor(editor) {
  const props = () => {
    var _ref, _RCEWrapper$getByEdit;
    return (_ref = (_RCEWrapper$getByEdit = RCEWrapper.getByEditor(editor)) === null || _RCEWrapper$getByEdit === void 0 ? void 0 : _RCEWrapper$getByEdit.props) !== null && _ref !== void 0 ? _ref : undefined;
  };
  let cachedCanvasToolId;
  function nonNullishArray(arr) {
    return arr === null || arr === void 0 ? void 0 : arr.filter(it => it != null);
  }
  return {
    editor: editor !== null && editor !== void 0 ? editor : null,
    get rceWrapper() {
      var _RCEWrapper$getByEdit2;
      return (_RCEWrapper$getByEdit2 = RCEWrapper.getByEditor(editor)) !== null && _RCEWrapper$getByEdit2 !== void 0 ? _RCEWrapper$getByEdit2 : null;
    },
    get availableRceLtiTools() {
      var _nonNullishArray, _props;
      return (_nonNullishArray = nonNullishArray((_props = props()) === null || _props === void 0 ? void 0 : _props.ltiTools)) !== null && _nonNullishArray !== void 0 ? _nonNullishArray : [];
    },
    /**
     * Gets information about the context in which the editor is launched.
     */
    get contextAssetInfo() {
      var _props2;
      const trayProps = (_props2 = props()) === null || _props2 === void 0 ? void 0 : _props2.trayProps;
      if (trayProps != null) {
        var _trayProps$containing;
        const {
          contextId,
          contextType
        } = (_trayProps$containing = trayProps.containingContext) !== null && _trayProps$containing !== void 0 ? _trayProps$containing : trayProps;
        if (contextId != null && contextId.length > 0 && contextType != null && contextType.length > 0) {
          return {
            contextType,
            contextId
          };
        }
      }
      return null;
    },
    get resourceSelectionUrlOverride() {
      var _props$externalToolsC, _props3, _props3$externalTools;
      return (_props$externalToolsC = (_props3 = props()) === null || _props3 === void 0 ? void 0 : (_props3$externalTools = _props3.externalToolsConfig) === null || _props3$externalTools === void 0 ? void 0 : _props3$externalTools.resourceSelectionUrlOverride) !== null && _props$externalToolsC !== void 0 ? _props$externalToolsC : null;
    },
    get ltiIframeAllowPolicy() {
      var _nonNullishArray2, _props4, _props4$externalTools;
      return ((_nonNullishArray2 = nonNullishArray((_props4 = props()) === null || _props4 === void 0 ? void 0 : (_props4$externalTools = _props4.externalToolsConfig) === null || _props4$externalTools === void 0 ? void 0 : _props4$externalTools.ltiIframeAllowances)) !== null && _nonNullishArray2 !== void 0 ? _nonNullishArray2 : fallbackIframeAllowances).join('; ');
    },
    get isA2StudentView() {
      var _props$externalToolsC2, _props5, _props5$externalTools;
      return (_props$externalToolsC2 = (_props5 = props()) === null || _props5 === void 0 ? void 0 : (_props5$externalTools = _props5.externalToolsConfig) === null || _props5$externalTools === void 0 ? void 0 : _props5$externalTools.isA2StudentView) !== null && _props$externalToolsC2 !== void 0 ? _props$externalToolsC2 : false;
    },
    get maxMruTools() {
      var _props$externalToolsC3, _props6, _props6$externalTools;
      return (_props$externalToolsC3 = (_props6 = props()) === null || _props6 === void 0 ? void 0 : (_props6$externalTools = _props6.externalToolsConfig) === null || _props6$externalTools === void 0 ? void 0 : _props6$externalTools.maxMruTools) !== null && _props$externalToolsC3 !== void 0 ? _props$externalToolsC3 : 5;
    },
    get canvasOrigin() {
      var _props$canvasOrigin, _props7;
      return (_props$canvasOrigin = (_props7 = props()) === null || _props7 === void 0 ? void 0 : _props7.canvasOrigin) !== null && _props$canvasOrigin !== void 0 ? _props$canvasOrigin : window.location.origin;
    },
    /**
     * Gets the context id that should be used when launching LTI iframes.
     */
    get containingCanvasLtiToolId() {
      var _props8, _props8$externalTools;
      const propsToolId = (_props8 = props()) === null || _props8 === void 0 ? void 0 : (_props8$externalTools = _props8.externalToolsConfig) === null || _props8$externalTools === void 0 ? void 0 : _props8$externalTools.containingCanvasLtiToolId;
      if (typeof propsToolId === 'string') {
        return propsToolId;
      }
      try {
        if (cachedCanvasToolId === undefined) {
          // Fall back on localStorage until NQ implements
          cachedCanvasToolId = window.localStorage.getItem('canvas_tool_id');
        }
        return cachedCanvasToolId;
      } catch {
        return null;
      }
    },
    get editorSelection() {
      var _editor$selection$get, _editor$selection;
      return (_editor$selection$get = editor === null || editor === void 0 ? void 0 : (_editor$selection = editor.selection) === null || _editor$selection === void 0 ? void 0 : _editor$selection.getContent()) !== null && _editor$selection$get !== void 0 ? _editor$selection$get : null;
    },
    get editorContent() {
      var _editor$getContent;
      return (_editor$getContent = editor === null || editor === void 0 ? void 0 : editor.getContent()) !== null && _editor$getContent !== void 0 ? _editor$getContent : null;
    },
    insertCode(code) {
      var _this$rceWrapper;
      if ((_this$rceWrapper = this.rceWrapper) !== null && _this$rceWrapper !== void 0 && _this$rceWrapper.insertCode) {
        this.rceWrapper.insertCode(code);
      }
    },
    replaceCode(code) {
      var _this$rceWrapper2;
      if ((_this$rceWrapper2 = this.rceWrapper) !== null && _this$rceWrapper2 !== void 0 && _this$rceWrapper2.replaceCode) {
        this.rceWrapper.replaceCode(code);
      }
    }
  };
}