// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*\n * Copyright (C) 2024 - present Instructure, Inc.\n *\n * This file is part of Canvas.\n *\n * Canvas is free software: you can redistribute it and/or modify it under\n * the terms of the GNU Affero General Public License as published by the Free\n * Software Foundation, version 3 of the License.\n *\n * Canvas is distributed in the hope that it will be useful, but WITHOUT ANY\n * WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR\n * A PARTICULAR PURPOSE. See the GNU Affero General Public License for more\n * details.\n *\n * You should have received a copy of the GNU Affero General Public License along\n * with this program. If not, see <http://www.gnu.org/licenses/>.\n */\n\n.contentLayout--tablet__1HO4D {\n    padding: 5rem 0 8rem; /* 80px 0 128px */\n}\n.contentLayout--desktop__sswiy {\n    padding: 0;\n}\n\n.contentLayout__content__169Ly {\n    height: 100%;\n    padding: 2.25rem 1.5rem; /* 36px 24px */\n}\n.contentLayout__content--tablet__39Ma8 {\n    height: unset;\n    margin: 0 auto;\n    padding: 3.75rem 6.25rem; /* 60px 100px */\n    width: 35.75rem; /* 572px */\n}\n.contentLayout__content--desktop__3751f {\n    height: 100%;\n    margin: unset;\n    padding: 5rem; /* 80px */\n    width: 45rem; /* 720px */\n}\n\n.contentLayout__background__27fkG {\n    bottom: 0;\n    left: 0;\n    position: absolute;\n    right: 0;\n    top: 0;\n}\n.contentLayout__background--desktop__1AmJS {\n    left: 45rem; /* 720px (width of content) */\n}\n", ""]);
// Exports
exports.locals = {
	"contentLayout--tablet": "contentLayout--tablet__1HO4D",
	"contentLayout--desktop": "contentLayout--desktop__sswiy",
	"contentLayout__content": "contentLayout__content__169Ly",
	"contentLayout__content--tablet": "contentLayout__content--tablet__39Ma8",
	"contentLayout__content--desktop": "contentLayout__content--desktop__3751f",
	"contentLayout__background": "contentLayout__background__27fkG",
	"contentLayout__background--desktop": "contentLayout__background--desktop__1AmJS"
};
module.exports = exports;
